// transcription-utils.ts
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Editor, Node, Point, Range, Text as SlateText, Transforms, createEditor } from 'slate';
import { ReactEditor, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Document, Paragraph, TextRun, Packer } from 'docx';
import { saveAs } from 'file-saver';
import {PodcastTranscriptionTaskMeta, TranscriptionSession} from "../../../models/Localization";
import {SlateParagraph} from "../../../models/Transcript";

export const createTranscriptionEditor = () =>
    withReact(withHistory(createEditor() as ReactEditor));

export const useTranscriptionState = (initialSession: TranscriptionSession) => {
    const editor = useMemo(() => createTranscriptionEditor(), []);
    const [transcriptLoading, setTranscriptLoading] = useState<boolean>(true);
    const [task, setTask] = useState<TranscriptionSession>(initialSession);
    const [value, setValue] = useState<Node[]>([]);
    const transcriptRef = useRef(null);

    return {
        editor,
        transcriptLoading,
        setTranscriptLoading,
        task,
        setTask,
        value,
        setValue,
        transcriptRef
    };
};

export const renderLeaf = ({ attributes, children, leaf }: {
    attributes: any,
    children: any,
    leaf: any
}) => {
    const extendedAttributes = {
        ...attributes,
        'data-start': leaf.start,
        'data-end': leaf.end
    };

    if (leaf.isAdvertisement) {
        children = <span style={{ backgroundColor: '#FFFFF0', color: '#B7791F' }}>{children}</span>;
    } else if (leaf.isInaudibleContent) {
        children = <span style={{ backgroundColor: '#FFF5F5', color: '#C53030'}}>{children}</span>;
    } else {
        children = <span style={{ backgroundColor: 'transparent' }}>{children}</span>;
    }

    if (leaf.highlight) {
        children = <span style={{ backgroundColor: '#FDE047' }}>{children}</span>;
    }
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
        children = <em>{children}</em>;
    }
    if (leaf.underlined) {
        children = <u>{children}</u>;
    }

    return <span {...extendedAttributes}>{children}</span>;
};

export const DefaultElement = ({...props}) => (
    <>
        <p {...props.attributes}>{props.children}</p>
        <br/>
    </>
);

export const getHighlightedTimestamps = (editor: ReactEditor) => {
    const { selection } = editor;

    if (!selection || Range.isCollapsed(selection)) return;

    const textNodes = Array.from(
        Editor.nodes(editor, {
            at: selection,
            match: n => SlateText.isText(n),
        })
    );

    if (!textNodes.length) return;

    const selectedText = Editor.string(editor, selection);
    return { startTime: 'NaN', endTime: 'NaN', text: selectedText };
};

export const isMarkActive = (editor: any, format: string) => {
    const marks: any = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const toggleFormatMark = (editor: any, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

export const convertToSlateParagraph = (data: any[]): SlateParagraph[] => {
    return data.map((item, index) => ({
        id: `${index}`,
        text: item.children.map((child: any) => child.text).join(''),
        start: item.start,
        end: item.end,
        speaker: item.speaker
    }));
};

export const createReadableTextFileWithSpeaker = async (
    value: Node[],
    task: TranscriptionSession
): Promise<Paragraph[]> => {
    const data = convertToSlateParagraph(value);
    let speaker = '';
    let paragraphs: Paragraph[] = [];

    // Add title
    paragraphs.push(new Paragraph({
        children: [new TextRun({
            text: (task as PodcastTranscriptionTaskMeta)?.episode.title as string,
            bold: true,
            size: 36,
        })],
        spacing: { after: 200 },
    }));

    // Add content paragraphs
    data.forEach(item => {
        if (speaker !== item.speaker) {
            speaker = item.speaker;
            paragraphs.push(new Paragraph({
                children: [
                    new TextRun({ text: `${speaker}: `, bold: true }),
                    new TextRun({ text: item.text })
                ],
                spacing: { after: 120 },
            }));
        } else {
            paragraphs.push(new Paragraph({
                children: [new TextRun({ text: item.text })],
                spacing: { after: 120 },
            }));
        }
    });

    return paragraphs;
};

export const generateDocument = async (
    value: Node[],
    task: TranscriptionSession
) => {
    const paragraphs = await createReadableTextFileWithSpeaker(value, task);
    const doc = new Document({
        sections: [{
            properties: {},
            children: paragraphs,
        }],
    });

    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${(task as PodcastTranscriptionTaskMeta)?.episode.title}.docx`);
    });
};

export const useTranscriptDataEffect = (
    isLoading: boolean,
    error: any,
    data: any,
    setValue: (value: Node[]) => void,
    setTask: (task: TranscriptionSession) => void,
    setTranscriptLoading: (loading: boolean) => void,
    toast: any
) => {
    useEffect(() => {
        if (!isLoading && data) {
            setTask(data.session);
            setValue(data.transcript);
            setTranscriptLoading(false);
        }

        if (!isLoading && error) {
            toast({
                title: 'Error',
                description: 'Could not fetch transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        }
    }, [isLoading, data, error, setValue, setTask, setTranscriptLoading, toast]);
};
