import { Box, Flex, Text, Button, Title, Divider, Stack } from '@mantine/core';
import { IconShoppingCart } from '@tabler/icons-react';
import { useAuth } from "../../context/AuthContext";
import React, { useEffect, useState } from "react";
import { TranscriptionOnlyItems, TranslationOnlyItems } from "./checkout-items";
import { PodcastCartItem } from "../../models/Cart";
import { ENDPOINTS, useApi } from "../../api/braincap-api";
import { useUser } from "../../context/UserContext";
import { PodcastTranscriptionConfig } from "../../models/Localization";
import { SplashLogo } from "../../assets/SplashIcon";
import { useCart } from "../../hooks/useCartQueries";
import {useParams} from "react-router-dom";

export default function CartPanel() {
    const auth: any = useAuth()
    const user: any = useUser()
    const [transcriptionItems, setTranscriptionItems] = useState<PodcastTranscriptionConfig[]>([])
    const [translationItems, setTranslationItems] = useState<PodcastTranscriptionConfig[]>([])
    const [transcriptionPrice, setTranscriptionPrice] = useState<number>(0)
    const [translationPrice, setTranslationPrice] = useState<number>(0)
    const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false)
    const { data: cart = [], isLoading: cartLoading } = useCart()
    const {podcastId} = useParams()

    const checkoutReq = useApi(ENDPOINTS.CART_CHECKOUT, user.token, false)

    useEffect(() => {
        if (!cartLoading && cart.length > 0) {
            updateCarts()
        }
    }, [cartLoading, cart])

    const updateCarts = () => {
        let transcription: PodcastTranscriptionConfig[] = []
        let translation: PodcastTranscriptionConfig[] = []
        cart.forEach((cartItem: any) => {
            if (cartItem.service.type === 'TRANSCRIPTION') {
                transcription.push(cartItem)
            } else {
                translation.push(cartItem)
            }
        })
        setTranscriptionItems(transcription)
        setTranslationItems(translation)
    }

    const handleCheckout = () => {
        if (translationItems.length > 0 || transcriptionItems.length > 0) {
            setCheckoutLoading(true)
            checkoutReq.setPayloadData({
                cart: cart,
                publisher: user.user
            })
        }
    }

    useEffect(() => {
        if (!checkoutReq.loading && checkoutReq.data) {
            if (checkoutReq.data) {
                setCheckoutLoading(false)
                window.location.href = checkoutReq.data.url
            }
        } else if (!checkoutReq.loading && checkoutReq.error) {
            setCheckoutLoading(false)
            // You might want to use Mantine's notification system here instead of Chakra's toast
        }
    }, [checkoutReq.loading, checkoutReq.data, checkoutReq.error])

    return (
        <Box w="100%" h="100vh" bg="gray.1" p="md">
            <Stack
                gap="md"
                style={(theme: any) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    borderRadius: theme.radius.lg,
                    boxShadow: theme.shadows.md,
                })}
                p="md"
            >
                <Flex align="center" justify="space-between">
                    <Title order={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <IconShoppingCart style={{ marginRight: '0.5rem' }} />
                        Cart
                    </Title>
                    <Flex align="center" gap="sm">
                        {podcastId &&
                            <Button variant="light" color="teal" onClick={() => {window.location.href = `/library/${podcastId}`}} >
                                Continue Shopping
                            </Button>
                        }
                        <Text size="sm" c="dimmed">
                            {cart.length} item(s)
                        </Text>
                    </Flex>
                </Flex>

                <Divider />

                {cart.length === 0 ? (
                    <Flex direction="column" align="center" justify="center" style={{ height: '300px' }}>
                        <Text size="xl" fw={500} mt="xl">
                            Your cart is empty. Add items to your cart to checkout
                        </Text>
                    </Flex>
                ) : (
                    <Box style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                        {transcriptionItems.length > 0 && (
                            <Box mb="xl">
                                <Title order={3} mb="md">TRANSCRIPTION</Title>
                                <TranscriptionOnlyItems cart={transcriptionItems} setTotalPrice={setTranscriptionPrice} />
                            </Box>
                        )}

                        {translationItems.length > 0 && (
                            <Box mt="xl">
                                <Title order={3} mb="md">TRANSLATION</Title>
                                <TranslationOnlyItems cart={translationItems} setTotalPrice={setTranslationPrice} />
                            </Box>
                        )}
                    </Box>
                )}

                <Divider />

                <Box>
                    <Title order={3} mb="md">ORDER SUMMARY</Title>
                    <Flex justify="space-between" align="center">
                        <Text fw={700}>Total</Text>
                        <Text fw={700}>${(translationPrice + transcriptionPrice).toFixed(2)}</Text>
                    </Flex>
                </Box>

                <Button
                    color="teal"
                    size="lg"
                    loading={checkoutLoading}
                    onClick={handleCheckout}
                    disabled={cart.length === 0}
                    fullWidth
                >
                    Checkout
                </Button>
            </Stack>
        </Box>
    )
}
