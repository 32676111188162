import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {createEditor, CustomText, Editor, Node, Point, Range, Text as SlateText, Transforms} from "slate";
import {FaBold, FaItalic, FaUnderline} from "react-icons/fa6";
import {withHistory} from "slate-history";
import {Editable, ReactEditor, Slate, useFocused, useSlate, withReact} from "slate-react";
import {
    Box,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SkeletonText,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import {RiPriceTag3Line, RiQuestionMark} from "react-icons/ri";
import {useUser} from "../../context/UserContext";
import {useAuth} from "../../context/AuthContext";
import {handleSplitParagraph} from "../editors/slate-helpers";
import {ToolbarButton, ToolbarMenu, ToolbarPortal} from "../editors/toolbar-helper";
import {useParams} from "react-router-dom";
import {PodcastTranscriptionTaskMeta, TranscriptionSession} from "../../models/Localization";
import {millisecondsToHHMMSS} from "../../utils/firebase";
import {ActionFilter} from "../editors/transcription-helpers";
import {SlateParagraph, TranscriptMode} from "../../models/Transcript";
import {Document, Packer, Paragraph, TextRun} from "docx";
import {saveAs} from "file-saver";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {useLibraryNavigation} from "../../context/LibraryNavigationContext";
import {
    useGetAsrTranscript,
    useGetFinalTranscript,
    useGetProofreadTranscript
} from "../../hooks/useLocalizationQueries";
import {TaskState, TranscriptionTaskStates} from "../../studio/transcription-studio";
import {rem} from "@mantine/core";
import {DownloadIcon} from "@chakra-ui/icons";

export const TranscriptCompleteEditor = ({mode, states}: {mode: TranscriptMode, states: TranscriptionTaskStates}) => {
    const user: any = useUser()
    const auth: any = useAuth()
    const libraryNavigation: any = useLibraryNavigation()
    const { sessionId, version }: any = useParams();


    const editor = useMemo(() => withReact(withHistory(createEditor() as ReactEditor)), []);
    const [transcriptLoading, setTranscriptLoading] = useState<boolean>(true);
    const [task, setTask] = useState<TranscriptionSession>(libraryNavigation.studioSession);
    const [value, setValue] = useState<Node[]>([]);
    const [lastHighlightedWordIndex, setLastHighlightedWordIndex] = useState<number | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef: any = React.useRef()
    const [actions, setActions] = useState<any[]>([]);
    const [filteredActions, setFilteredActions] = useState<any[]>([]);
    const [openActionBox, setOpenActionBox] = useState<boolean>(false);
    const shouldFetchAsr = useMemo(() => {
        return mode === TranscriptMode.ASR &&
            (states["AI"] === TaskState.IN_PROGRESS || states["AI"] === TaskState.COMPLETED);
    }, [mode, states]);

    const shouldFetchProofread = useMemo(() => {
        return mode === TranscriptMode.PROOFREAD &&
            (states["TRANSCRIPTIONIST"] === TaskState.IN_PROGRESS ||
                states["TRANSCRIPTIONIST"] === TaskState.COMPLETED);
    }, [mode, states]);

    const shouldFetchComplete = useMemo(() => {
        return mode === TranscriptMode.COMPLETED &&
            states["FINAL"] === TaskState.COMPLETED;
    }, [mode, states]);

    const {data: asrTranscript, isLoading: isAsrTranscriptLoading, error: asrTranscriptError} = useGetAsrTranscript(task, shouldFetchAsr, states, mode);
    const {data: proofreadTranscript, isLoading: isProofreadTranscriptLoading, error: proofreadTranscriptError} = useGetProofreadTranscript(task, states, mode, shouldFetchProofread);
    const {data: completeTranscript, isLoading: isCompleteTranscriptLoading, error: completeTranscriptError} = useGetFinalTranscript(task, states, mode, shouldFetchComplete);
    const [speakersList, setSpeakersList] = useState<any[]>([]);
    const [playSegment, setPlaySegment] = useState<boolean>(false);
    const [playSegmentStart, setPlaySegmentStart] = useState<number>(0);
    const toast = useToast();
    const transcriptRef: any = useRef(null);
    const [episode, setEpisode] = useState<PodcastEpisode>()

    useEffect(() => {
        // Clear previous state
        setValue([]);
        setEpisode(undefined);
        setTranscriptLoading(true);

        // Prioritize based on mode
        switch (mode) {
            case TranscriptMode.ASR:
                if (!isAsrTranscriptLoading && !!asrTranscript) {
                    setValue(asrTranscript.transcript);
                    setEpisode(asrTranscript.episode);
                    setTranscriptLoading(false);
                }
                break;
            case TranscriptMode.PROOFREAD:
                if (!isProofreadTranscriptLoading && !!proofreadTranscript) {
                    setTask(proofreadTranscript.session)
                    setEpisode(proofreadTranscript.episode)
                    setValue(proofreadTranscript.transcript)
                    setEpisode(proofreadTranscript.episode)
                    setTranscriptLoading(false)
                }
                break;
            case TranscriptMode.COMPLETED:
                if (!isCompleteTranscriptLoading && !!completeTranscript) {
                    setTask(completeTranscript.session)
                    setValue(completeTranscript.transcript);
                    setEpisode(completeTranscript.episode);
                    setTranscriptLoading(false);
                }
                break;
        }
    }, [mode, asrTranscript, proofreadTranscript, completeTranscript,
        isAsrTranscriptLoading, isProofreadTranscriptLoading, isCompleteTranscriptLoading]);

    useEffect(() => {
        if (!isCompleteTranscriptLoading && !!completeTranscript) {
            setTask(completeTranscript.session)
            setValue(completeTranscript.transcript)
            setEpisode(completeTranscript.episode)
            setTranscriptLoading(false)
        }

        if (!isCompleteTranscriptLoading && completeTranscriptError) {
            toast({
                title: 'Error',
                description: 'Could not fetch transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        }
    }, [isCompleteTranscriptLoading, completeTranscriptError, sessionId, completeTranscript])

    useEffect(() => {
        if (!isAsrTranscriptLoading && !!asrTranscript) {
            setValue(asrTranscript.transcript)
            setEpisode(asrTranscript.episode)
            setTranscriptLoading(false)
        }

        if (!isAsrTranscriptLoading && asrTranscriptError) {
            toast({
                title: 'Error',
                description: 'Could not fetch transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        }
    }, [isAsrTranscriptLoading, asrTranscriptError, sessionId, asrTranscript])

    useEffect(() => {
        if (!isProofreadTranscriptLoading && !!proofreadTranscript) {
            setTask(proofreadTranscript.session)
            setEpisode(proofreadTranscript.episode)
            setValue(proofreadTranscript.transcript)
            setEpisode(proofreadTranscript.episode)
            setTranscriptLoading(false)
        }

        if (!isProofreadTranscriptLoading && proofreadTranscriptError) {
            toast({
                title: 'Error',
                description: 'Could not fetch transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        }
    }, [isProofreadTranscriptLoading, proofreadTranscript, sessionId, proofreadTranscriptError])

    useEffect(() => {
        setFilteredActions(actions)
    }, [actions])

    const renderElement = useCallback(({...props}) => {
        let list = props.element.speakersList
        switch (props.element.type) {
            case 'timedText':
                return (
                    <Flex direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} w={'full'} {...props.attributes} sx={
                        {
                            textColor: '#171923',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                            fontFamily: 'sans-serif',
                            fontStyle: 'normal',
                        }}>
                        <Menu>
                            <MenuButton userSelect={'none'}>
                                <Text contentEditable={false} color="teal.600" fontSize="12px" fontWeight="600" lineHeight="16px">
                                    {props.element.speaker}
                                </Text>
                            </MenuButton>
                            <MenuList>
                                {list.map((speaker: any) => {
                                    return (
                                        <MenuItem contentEditable={false} onClick={handleSetSpeaker.bind(this, props, speaker.name, true)}>
                                            {speaker.name}
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </Menu>
                        <p>
                            {props.children}
                        </p>
                        <br/>
                    </Flex>
                )
            default:
                return <DefaultElement {...props} />;

        }
    }, []);

    const renderLeaf = useCallback(({ attributes, children, leaf }: { attributes: any, children: any, leaf: any }) => {
        const extendedAttributes = {
            ...attributes,
            'data-start': leaf.start,
            'data-end': leaf.end
        };

        if (leaf.isAdvertisement) {
            children = <span style={{ backgroundColor: '#FFFFF0', color: '#B7791F' }}>{children}</span>;
        } else if (leaf.isInaudibleContent) {
            children = <span style={{ backgroundColor: '#FFF5F5', color: '#C53030'}}>{children}</span>;
        } else {
            children = <span style={{ backgroundColor: 'transparent' }}>{children}</span>;
        }

        if (leaf.highlight) {
            children = <span style={{ backgroundColor: '#FDE047' }}>{children}</span>;
        }
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underlined) {
            children = <u>{children}</u>
        }

        return <span {...extendedAttributes}>{children}</span>;
    }, []);

    const handleSetSpeaker = (props: any, name: string) => {
        const pathToCurrentNode = ReactEditor.findPath(editor, props.element);
        const oldSpeakerName = props.element.speaker;
        const isUpdateAllSpeakerInstances = window.confirm(`Would you like to replace all occurrences of ${oldSpeakerName} with ${name}?`);

        if (isUpdateAllSpeakerInstances) {
            const rangeForTheWholeEditor = Editor.range(editor, []);
            Transforms.setNodes(
                editor,
                { type: 'timedText', speaker: name } as Partial<Node>,
                {
                    at: rangeForTheWholeEditor,
                    match: (node: any) => node.type === 'timedText' && node.speaker.toLowerCase() === oldSpeakerName.toLowerCase(),
                }
            );
        } else {
            Transforms.setNodes(editor, {type: 'timedText', speaker: name} as Partial<Node>, { at: pathToCurrentNode })
        }
    }

    const DefaultElement = ({...props}) => {
        return (
            <>
                <p {...props.attributes}>{props.children}</p>
                <br/>
            </>
        );
    };

    const handleChange = (data: any) => {
        setValue(data);
        //saveLocalTranscript(data);
        if (actions.length > 0) {
            setOpenActionBox(true)
        } else {
            setOpenActionBox(false)
        }
    };

    const handleOnKeyDown = (event: any) => {
        if (event.key === '&') {
            // Prevent the ampersand character from being inserted.
            event.preventDefault()
            // Execute the `insertText` method when the event occurs.
            editor.insertText('and')
        }

        if (event.key === 'Enter') {
            // intercept Enter, and handle timecodes when splitting a paragraph
            event.preventDefault();
            // console.info('For now disabling enter key to split a paragraph, while figuring out the aligment issue');
            // handleSetPauseWhileTyping();
            // TODO: Edge case, hit enters after having typed some other words?
            handleSplitParagraph(editor);
        }
    }

    const getHighlightedTimestamps = (editor: any) => {
        const { selection } = editor;

        if (!selection || Range.isCollapsed(selection)) return;

        const isReversed = Point.isAfter(selection.anchor, selection.focus);

        const textNodes = Array.from(
            Editor.nodes(editor, {
                at: selection,
                match: n => SlateText.isText(n),
            })
        );

        if (!textNodes.length) return;
        console.log(textNodes)

        const [firstTextNode, firstPath] = textNodes[0];
        const [lastTextNode, lastPath] = textNodes[textNodes.length - 1];


        const selectedText = Editor.string(editor, selection);

        return { startTime: 'NaN', endTime: 'Nan', text: selectedText };
    };

    const findWordForTextNode = (textNode: any, path: any, editor: any, offset: number) => {
        const [parentNode]: any = Editor.parent(editor, path);

        if (!parentNode.children || !parentNode.children[0].words) return null;

        const wordsArray = parentNode.children[0].words;

        // Split the textNode text into individual words
        const textNodeWords = textNode.text.split(/\s+/);

        // Determine which word the offset falls within
        let currentLength = 0;
        let targetWord: any = null;
        for (let word of textNodeWords) {
            currentLength += word.length + 1; // +1 for the space
            if (currentLength >= offset) {
                targetWord = word;
                break;
            }
        }

        if (!targetWord) return null;

        // Find the corresponding word object for the target word
        const wordObj = wordsArray.find((word: any) => word.text === targetWord);

        return wordObj;
    }

    const HoveringToolbar = () => {
        const ref = useRef<HTMLDivElement | null>()
        const editor = useSlate()
        const inFocus = useFocused()
        const [openEditBox, setOpenEditBox] = useState<boolean>(false)

        useEffect(() => {
            try {
                const el = ref.current
                const { selection } = editor
                if (!el) {
                    return
                }

                if (
                    !selection ||
                    !inFocus ||
                    Range.isCollapsed(selection) ||
                    Editor.string(editor, selection) === ''
                ) {
                    el.removeAttribute('style')
                    return
                }

                const domSelection: any = window.getSelection()
                const domRange = domSelection.getRangeAt(0)
                const rect = domRange.getBoundingClientRect();
                el.style.opacity = '1';
                el.style.top = `${rect.bottom + window.scrollY}px`;
                el.style.left = `${rect.left + window.scrollX + (rect.width / 2) - (el.offsetWidth / 2)}px`// <-- Call the function here
            } catch (e) {
                console.log(e)
            }

        } )

        return (
            <ToolbarPortal  ref={ref}>
                <ToolbarMenu
                    ref={ref}
                    onMouseDown={(e: any) => {e.preventDefault()}}
                >
                    <AdvertisementButton
                        icon={<RiPriceTag3Line style={{color: '#B7791F',}}/>}
                        name={'Advertisement'}
                        activeName={'Remove Advertisement'}
                    />
                    <InaudibleContentButton
                        icon={<RiQuestionMark style={{color: '#C53030'}}/>}
                        name={'Inaudible Content'}
                        activeName={'Remove Inaudible Content'}
                    />
                    <FormatButton format="bold" icon={<FaBold />} />
                    <FormatButton format="italic" icon={<FaItalic />} />
                    <FormatButton format="underlined" icon={<FaUnderline />} />
                </ToolbarMenu>
            </ToolbarPortal>
        )
    }

    const toggleFormatMark = (editor: any, format: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const FormatButton = ({ format, icon }: { format: any, icon: any}) => {
        const editor = useSlate()
        let data = getHighlightedTimestamps(editor)
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => toggleFormatMark(editor, format, data?.startTime, data?.endTime)}
            >
                <Icon>{icon}</Icon>
            </ToolbarButton>
        )
    }

    const EditContentButton = ({ format, icon, name, activeName, openEditBox }: { format: any, icon: any, name: string, activeName: string, openEditBox: any }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, format)
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'inaudibleContent'
        }
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, format)}
                onClick={() => {openEditBox(true)}}
            >
                {icon}
                <Text color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const AdvertisementButton = ({ icon, name, activeName }: { icon: any, name: string, activeName: string }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, 'isAdvertisement')
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'advertisement'
        }
        return (
            <ToolbarButton
                reversed
                active={isActive}
                onClick={() => {toggleMark(editor, data?.startTime, data?.endTime)}}
                bg={isActive? "yellow.50" : "white"}
            >
                {icon}
                <Text color={isActive? "yellow.600" : "gray.600"} fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const InaudibleContentButton = ({ icon, name, activeName }: { icon: any, name: string, activeName: string }) => {
        const editor = useSlate()
        const isActive = isMarkActive(editor, 'isInaudibleContent')
        let data = getHighlightedTimestamps(editor)
        let action = {
            ...data,
            type: 'inaudibleContent'
        }
        return (
            <ToolbarButton
                reversed
                active={isMarkActive(editor, 'isInaudibleContent')}
                onClick={() => {InaudibleMark(editor, data?.startTime, data?.endTime)}}>
                {icon}
                <Text color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                    {isActive? activeName : name}
                </Text>
            </ToolbarButton>
        )
    }

    const toggleMark = (editor: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, 'isAdvertisement')
        console.log(`adstimestamps: ${start} - ${end}`)

        if (isActive) {
            Editor.removeMark(editor, 'isAdvertisement')
        } else {
            Editor.addMark(editor, 'isAdvertisement', true)
            Editor.addMark(editor, 'start', start)
            Editor.addMark(editor, 'end', end)
        }
    }

    const InaudibleMark = (editor: any, start: any, end: any) => {
        const isActive = isMarkActive(editor, 'isInaudibleContent')
        console.log(`inaudibletimestamps: ${start} - ${end}`)
        if (isActive) {
            Editor.removeMark(editor, 'isInaudibleContent')
        } else {
            Editor.addMark(editor, 'isInaudibleContent', true)
            Editor.addMark(editor, 'start', start)
            Editor.addMark(editor, 'end', end)
        }
    }

    const isMarkActive = (editor: any, format: any) => {
        const marks: any = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }

    const convertToSlateParagraph = (data: any[]): SlateParagraph[] => {
        return data.map((item, index) => {
            // No conditional spacing added, just combine texts
            const combinedText = item.children.map((child: any) => child.text).join('');
            return {
                id: `${index}`,
                text: combinedText,
                start: item.start,
                end: item.end,
                speaker: item.speaker
            };
        });
    }

    const createReadableTextFileWithSpeaker = async (): Promise<Paragraph[]> => {
        let data = convertToSlateParagraph(value);
        console.log(`Conversion complete`);

        let speaker = '';
        let paragraphs: Paragraph[] = [];
        paragraphs.push(new Paragraph({
            children: [new TextRun({
                text: (task as PodcastTranscriptionTaskMeta)?.episode.title as string,
                bold: true,
                size: 36,
            })],
            spacing: { after: 200 }, // Adds space after the title
        }));

        data.forEach(item => {
            if (speaker !== item.speaker) {
                speaker = item.speaker;
                paragraphs.push(new Paragraph({
                    children: [
                        new TextRun({
                            text: `${speaker}: `,
                            bold: true,
                        }),
                        new TextRun({
                            text: item.text,
                        })
                    ],
                    spacing: { after: 120 }, // Adjust space after each speaker change
                }));
            } else {
                paragraphs.push(new Paragraph({
                    children: [new TextRun({
                        text: item.text,
                    })],
                    spacing: { after: 120 }, // Consistent spacing for readability
                }));
            }
        });

        return paragraphs;
    };

    const generateDocument = () => {
        let transcriptText = createReadableTextFileWithSpeaker()
        transcriptText.then((pagraphs) => {
            const doc: any = new Document({
                sections: [
                    {
                        properties: {},
                        children: pagraphs,
                    },
                ],
            });

            Packer.toBlob(doc).then((blob) => {
                saveAs(blob, `${(task as PodcastTranscriptionTaskMeta)?.episode.title}-test.docx`);
            });
        })
    }

    // @ts-ignore
    // @ts-ignore
    return (
        <Flex w={'full'} h={'100vh'} direction={'column'} gap={'36px'}>
            <Flex ref={transcriptRef} w={'full'} h={'100%'} style={{ overflow: 'scroll' }}>
                {transcriptLoading?
                    <SkeletonText w={'full'} h={'full'} mt='4' noOfLines={60} spacing='4' skeletonHeight='2' />
                    :
                    <Flex direction={'column'} gap={'10px'} align={'center'} justify={'center'} w={'100%'} h={'100%'}>
                        <Flex w={'full'} align={'center'} justify={'flex-end'}>
                            <DownloadIcon style={{ width: rem(14), height: rem(14) }} onClick={generateDocument}/>
                        </Flex>
                        <Slate
                            editor={editor}
                            onChange={handleChange}
                            value={value}>
                            <HoveringToolbar />
                            <Editable
                                contentEditable={true}
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                                onKeyDown={handleOnKeyDown}
                                autoFocus
                                style={{ height: '100vh', overflowY: 'scroll' }}
                            />
                        </Slate>
                    </Flex>
                }
            </Flex>
        </Flex>
    );
}
