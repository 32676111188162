import {Badge, Group} from "@mantine/core";
import {IconBrandYoutube, IconRss, IconSvg} from "@tabler/icons-react";
import React from "react";

export const BraincapRSSBadge = () => {
    return (
        <Badge
            leftSection={<IconRss size={14} />}
            color="orange"
            variant="light"
            size="sm"
            w="fit-content"
        >
            RSS Feed
        </Badge>
    )
}

export const BraincapYoutubeBadge = () => {
    return (
        <Badge
            leftSection={<IconBrandYoutube size={14} />}
            color="red"
            variant="light"
            size="sm"
            w="fit-content"
        >
            YouTube Playlist
        </Badge>
    )
}

export const LibraryIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 18 18" fill="none">
        <path
            d="M12.375 14.484V3.51521C12.375 3.29144 12.2861 3.07683 12.1279 2.91859C11.9696 2.76036 11.755 2.67146 11.5312 2.67146C11.3075 2.67146 11.0929 2.76036 10.9346 2.91859C10.7764 3.07683 10.6875 3.29144 10.6875 3.51521V14.484C10.6875 14.7077 10.7764 14.9223 10.9346 15.0806C11.0929 15.2388 11.3075 15.3277 11.5312 15.3277C11.755 15.3277 11.9696 15.2388 12.1279 15.0806C12.2861 14.9223 12.375 14.7077 12.375 14.484Z"
            fill="#1A202C"
        />
        <path
            d="M15.75 5.41365C15.75 5.18987 15.6611 4.97526 15.5029 4.81703C15.3446 4.6588 15.13 4.5699 14.9062 4.5699C14.6825 4.5699 14.4679 4.6588 14.3096 4.81703C14.1514 4.97526 14.0625 5.18987 14.0625 5.41365V12.5855C14.0625 12.8093 14.1514 13.0239 14.3096 13.1821C14.4679 13.3404 14.6825 13.4293 14.9062 13.4293C15.13 13.4293 15.3446 13.3404 15.5029 13.1821C15.6611 13.0239 15.75 12.8093 15.75 12.5855V5.41365Z"
            fill="#1A202C"
        />
        <path
            d="M3.48398 14.4375L6.16078 15.585C6.41381 15.6944 6.68668 15.7504 6.96234 15.7496C7.50259 15.749 8.02055 15.5341 8.40257 15.1521C8.78458 14.7701 8.99944 14.2522 9 13.7119V4.28722C8.99958 3.95007 8.91559 3.61828 8.75555 3.32153C8.59552 3.02478 8.36442 2.77233 8.08293 2.58677C7.80144 2.4012 7.47834 2.2883 7.14254 2.25816C6.80674 2.22803 6.4687 2.28161 6.15867 2.4141L3.48398 3.5616C3.11777 3.71848 2.80564 3.97939 2.58629 4.31197C2.36694 4.64456 2.25001 5.0342 2.25 5.43261V12.5665C2.24974 12.965 2.36656 13.3547 2.58594 13.6874C2.80533 14.02 3.1176 14.2809 3.48398 14.4375ZM3.9375 5.43261C3.93763 5.36444 3.95766 5.29778 3.99513 5.24083C4.0326 5.18387 4.08588 5.13909 4.14844 5.11199L6.82313 3.96449C6.86672 3.94689 6.91322 3.93759 6.96024 3.93706C7.0064 3.93679 7.05216 3.94564 7.09488 3.96311C7.13761 3.98058 7.17646 4.00633 7.2092 4.03887C7.24194 4.07142 7.26792 4.11011 7.28565 4.15273C7.30338 4.19535 7.3125 4.24106 7.3125 4.28722V13.7119C7.3125 13.8048 7.27561 13.8938 7.20994 13.9595C7.14428 14.0252 7.05521 14.0621 6.96234 14.0621C6.91533 14.0615 6.86883 14.0522 6.82523 14.0346L4.14844 12.8871C4.08588 12.86 4.0326 12.8153 3.99513 12.7583C3.95766 12.7013 3.93763 12.6347 3.9375 12.5665V5.43261Z"
            fill="#1A202C"
        />
    </svg>
);


