import React, {useEffect} from 'react';
import {Flex, Text, Avatar, Badge, NavLink, Divider, Image} from '@mantine/core';
import { FiHome, FiLogOut, FiShoppingCart } from 'react-icons/fi';
import {Podcast} from "../models/Podcast";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useHover } from '@mantine/hooks';
import {LibraryIcon} from "../assets/library-tags";
interface PodcastNavbarProps {
    podcasts: any[];
    cart: any[];
    onLogout: () => void;
}


const PodcastNavItem: React.FC<{ podcast: Podcast; active?: boolean; onClick: () => void }> = ({
    podcast,
    active,
    onClick
}) => {
    const { hovered, ref } = useHover()
    const navigate = useNavigate()

    return (
        <Flex
            ref={ref}
            onClick={onClick}
            style={{
                display: 'flex',
                padding: '0px 12px',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '12px',
                alignSelf: 'stretch',
                cursor: 'pointer',
                height: '40px',
                backgroundColor: active ? 'var(--mantine-color-gray-2)' : hovered ? 'var(--mantine-color-gray-0)' : undefined
            }}
        >
            <Image
                src={podcast.image_url}
                alt={podcast.title}
                w={'24px'}
                h={'24px'}
                radius="sm"
            />
            <Text
                style={{
                    color: 'var(--gray-800, #1A202C)',
                    fontFamily: 'sans-serif',
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px'
                }}
                truncate
            >
                {podcast.title}
            </Text>
        </Flex>
    );
};

export const PodcastNavbar: React.FC<PodcastNavbarProps> = ({ podcasts, cart, onLogout }) => {
    const [activePodcast, setActivePodcast] = React.useState<Podcast>();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const podcastId = useParams<{ podcastId: string }>().podcastId;

    useEffect(() => {
        if (pathname.includes('/library/')) {
            setActivePodcast(podcasts.find((p) => p.id === podcastId));
        }
    }, [podcastId, pathname, podcasts]);

    return (
        <Flex direction="column" gap="sm" p="md" style={{ width: '100%' }}>
            <NavLink
                label={
                    <Text style={{
                        color: 'var(--gray-800, #1A202C)',
                        fontFamily: 'sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                    }}>Library</Text>
                }
                leftSection={<LibraryIcon />}
                onClick={() => navigate('/library')}
                style={{ borderRadius: '8px' }}
            />

            <Flex
                direction="column"
                style={{
                    gap: '4px',
                    overflowY: 'auto',
                    flex: 1,
                    marginBottom: 'auto',
                }}
            >
                {podcasts.map((podcast) => (
                    <PodcastNavItem
                        key={podcast.id}
                        podcast={podcast}
                        active={activePodcast?.id === podcast.id}
                        onClick={() => {
                            setActivePodcast(podcast);
                            navigate(`/library/${podcast.id}`);
                        }}
                    />
                ))}
            </Flex>

            <Divider />

            <NavLink
                label={
                    <Text style={{
                        color: 'var(--gray-800, #1A202C)',
                        fontFamily: 'sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                    }}>Cart</Text>
                }
                leftSection={<FiShoppingCart size={18} style={{ width: '18px', height: '18px' }} />}
                rightSection={
                    cart.length > 0 ? (
                        <Badge color="red" variant="filled">
                            {cart.length}
                        </Badge>
                    ) : null
                }
                onClick={() => navigate('/cart')}
                style={{ borderRadius: '8px' }}
            />
            <NavLink
                label={
                    <Text style={{
                        color: 'var(--gray-800, #1A202C)',
                        fontFamily: 'sans-serif',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                    }}>Logout</Text>
                }
                leftSection={<FiLogOut size={18} style={{ width: '18px', height: '18px' }} />}
                onClick={onLogout}
                style={{ borderRadius: '8px' }}
            />
        </Flex>
    );
};
