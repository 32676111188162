import React from 'react';
import { Center, Stack, Text, RingProgress, Paper } from '@mantine/core';

type TaskState = 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED';

interface TranscriptProgressProps {
    taskState: TaskState;
}

const TranscriptProgress = ({ taskState }: any) => {
    const getProgressValue = () => {
        switch (taskState) {
            case 'NOT_STARTED':
                return 0;
            case 'IN_PROGRESS':
                return 65;
            default:
                return 0;
        }
    };

    const getMessage = () => {
        switch (taskState) {
            case 'NOT_STARTED':
                return 'Transcript processing has not started yet';
            case 'IN_PROGRESS':
                return 'Transcript is currently being processed';
            default:
                return 'Waiting for transcript';
        }
    };

    return (
        <Center p="xl" h={400}>
            <Stack align="center" gap="lg">
                <Paper
                    p="xl"
                    radius="md"
                    className="animate-pulse"
                    style={(theme: any) => ({
                        backgroundColor: theme.colors.gray[0],
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.02)',
                        },
                    })}
                >
                    <Stack align="center" gap="md">
                        <RingProgress
                            size={120}
                            thickness={8}
                            roundCaps
                            sections={[{ value: getProgressValue(), color: 'blue' }]}
                        />
                        <Text size="lg" fw={500} c="dimmed">
                            {getMessage()}
                        </Text>
                    </Stack>
                </Paper>
            </Stack>
        </Center>
    );
};

export default TranscriptProgress;
