import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {createEditor, Editor, Node, Transforms} from "slate";
import {withHistory} from "slate-history";
import {Editable, ReactEditor, Slate, withReact} from "slate-react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    ButtonGroup,
    Center,
    Flex,
    SkeletonText,
    Spinner,
    Tag,
    Text,
    useBreakpointValue,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import {useUser} from "../../context/UserContext";
import {useAuth} from "../../context/AuthContext";
import {apiStates, useApi} from "../../api/braincap-api";

import {useParams} from "react-router-dom";
import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {filterActionItems} from "../editors/timestamps-helpers";
import {PopoverMenu} from "../reviewer/reviewer-toolbar-buttons";
import {CustomPagination, CustomParagraphPagination} from "../custom-pagination";
import {CheckIcon} from "@chakra-ui/icons";
import {useQueryClient} from "@tanstack/react-query";

export const TranscriptReviewEditor = () => {
    const user: any = useUser()
    const auth: any = useAuth()
    const { sessionId, podcastId, episodeId }: any = useParams();

    const editor = useMemo(() => withReact(withHistory(createEditor() as ReactEditor)), []);
    const [transcriptLoading, setTranscriptLoading] = useState<boolean>(true);
    const [transcriptSaving, setTranscriptSaving] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [nodes, setNodes] = useState<Node[]>([]);
    const [task, setTask] = useState<TranscriptionSession>();
    const [value, setValue] = useState<Node[]>([]);
    const [actionLocations, setActionLocations] = useState<any>({});
    const cancelRef: any = React.useRef()
    const [actions, setActions] = useState<any[]>([]);
    const [filteredActions, setFilteredActions] = useState<any[]>([]);
    const [openActionBox, setOpenActionBox] = useState<boolean>(true);
    const transcriptReq = useApi(`/reviewer/transcript/get/${sessionId}/${podcastId}/${episodeId}`, user.token, false);
    const transcriptSaveReq = useApi(`/publisher/review/save/${sessionId}`, user.token, false);
    const transcriptSubmitReq = useApi(`/publisher/review/complete/${sessionId}`, user.token, false);
    const { onOpen: customOnOpen, onClose: customOnClose, isOpen: customIsOpen } = useDisclosure();
    const toast = useToast();
    const transcriptRef: any = useRef(null);
    const { onOpen: submitOnOpen, onClose: submitOnClose, isOpen: submitIsOpen } = useDisclosure();
    const { onOpen: actionOnOpen, onClose: actionOnClose, isOpen: actionIsOpen } = useDisclosure();
    const [proposals, setProposal] = useState<any[]>([])
    const [totalReviewActions, setTotalReviewActions] = useState<number>(0)
    const [totalResolvedActions, setTotalResolvedActions] = useState<number>(0)
    const [actionKeyDownData, setActionKeyDownData] = useState<any>({});
    const [originalTranscript, setOriginalTranscript] = useState<Node[]>([]);
    const queryClient = useQueryClient();


    const [page, setPage] = useState(1); // initialize the current page to 1
    const dynamicPageSize = useBreakpointValue({ base: 12, md: 12, lg: 12 , xl: 8 });
    const [pageSize, setPageSize] = useState(1);
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const [filteredValue, setFilteredValue] = useState<Node[]>([]);

    useEffect(() => {
        if (!user.loading && !!user.user) {
            setTranscriptLoading(true)
            transcriptReq.setPayloadData({})
        }
    }, [user.loading])

    const createIndexTranscript = (transcript: any): any => {
        console.log(transcript)
        let tIndex = 0;
        let cIndex = 0;
        let indexedTranscript = transcript.map((paragraph: any, index: any) => {
            let newParagraph = { ...paragraph, id: tIndex };
            let actionChildrens: any[] = []
            newParagraph.children = paragraph.children.map((children: any, childrenIndex: any) => {
                let path = [tIndex, cIndex];
                let newChildren = { ...children, id: cIndex, path: path };
                //remove words key
                if (children.isAction) {
                    newParagraph = {
                        ...newParagraph,
                        hasAction: true,
                    };
                }

                if (children.isComment) {
                    newParagraph = {
                        ...newParagraph,
                        hasComment: true,
                    };

                }

                newChildren.path = path;
                children = newChildren;
                cIndex++;   // Moved increment operation here
                return newChildren;
            });

            tIndex++;
            return newParagraph;
        });
        return indexedTranscript;
    }

    const calculateTotalReviewActions = (transcript: any) => {
        let total = 0
        transcript.forEach((paragraph: any) => {
            paragraph.children.forEach((children: any) => {
                if (children.isAction) {
                    total++
                }
            })
        })
        setTotalReviewActions(total)
    }

    const calculateTotalResolvedActions = (transcript: any) => {
        let total = 0
        transcript.forEach((paragraph: any) => {
            paragraph.children.forEach((children: any) => {
                if (children.isAction && children.resolved !== undefined && children.resolved) {
                    total++
                }
            })
        })
        setTotalResolvedActions(total)
    }


    useEffect(() => {
        if (!transcriptReq.loading && !!transcriptReq.data ) {
            setTask(transcriptReq.data.session)
            let transcript = transcriptReq.data.transcript;
            setOriginalTranscript(transcript)
            // Create a new array with updated paragraphs.
            let indexedTranscript = createIndexTranscript(transcript);
            calculateTotalReviewActions(indexedTranscript)
            calculateTotalResolvedActions(indexedTranscript)
            setValue(indexedTranscript);
            //library.setNavBarEpisodeTitle(transcriptReq.data.session.episode.title)
            //library.setOverrideNavBarEpisodeTitle(true)
            //setActions(transcriptReq.data.actions)
            setTranscriptLoading(false)
        }
    }, [transcriptReq.loading, transcriptReq.data])

    useEffect(() => {
        // Filter the value for elements that have 'hasAction' or 'hasComment'
        const actionOrCommentElements = value.filter((node: any) => {
            if (node.hasAction || node.hasComment) {
                // Check if there are any unresolved actions in the children
                return true;
            }
            return false;
        });
        setFilteredValue(actionOrCommentElements);
    }, [value]);

    useEffect(() => {
        setFilteredActions(actions)
    }, [actions])

    useEffect(() => {
        if (value.length > 0) {
            let newActions: any = filterActionItems(value)
            setProposal(newActions)
        }
    }, [value])

    useEffect(() => {
        if (!transcriptSaveReq.loading && transcriptSaveReq.state === apiStates.SUCCESS) {
            toast({
                title: 'Success',
                description: 'Transcript saved successfully',
                status: 'success',
                duration: 1000,
                isClosable: true,
                position: 'top-right'

            })
            setActions(transcriptSaveReq.data.actions)
            setTranscriptSaving(false)
        }
        if (!transcriptSaveReq.loading && transcriptSaveReq.error) {
            toast({
                title: 'Error',
                description: 'Could not save transcript. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
            setTranscriptSaving(false)
        }
    }, [transcriptSaveReq.loading, transcriptSaveReq.data])

    useEffect(() => {
        if (!transcriptSubmitReq.loading && transcriptSubmitReq.state === apiStates.SUCCESS) {
            toast({
                title: 'Transcript session completed',
                description: `Transcript session completed successfully`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

            //window.history.back()
            queryClient.invalidateQueries({queryKey: ['episodes']});
            queryClient.invalidateQueries({queryKey: ['transcriptionSessions']});
            window.history.back()

        }

        if (!transcriptSubmitReq.loading && transcriptSubmitReq.error) {
            toast({
                title: 'Error',
                description: 'Could not save session. Please try again or contact support.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        setSubmitting(false)
    }, [transcriptSubmitReq.loading, transcriptSubmitReq.data])
    const getSlateContent = () => {
        return value;
    };
    const DefaultElement = ({...props}) => {
        return (
            <>
                <p {...props.attributes}>{props.children}</p>
                <br/>
            </>
        );
    };
    const handleChange = (data: any) => {
        calculateTotalResolvedActions(data)
        setValue(data);
        const isAstChange = editor.operations.some(
            op => 'set_selection' !== op.type
        )
        if (isAstChange) {
        }
    };

    const renderElement = useCallback(({...props}) => {
        const index = filteredValue.findIndex(filteredNode => filteredNode === props.element);
        let list = props.element.speakersList
        let isAction = props.element.hasAction
        const isElementInPageRange = index >= startIndex && index < endIndex;

        // Only render the element if it is within the range of the current page
        switch (props.element.type) {
            case 'timedText':
                return (
                    <>
                        {isElementInPageRange?
                            <Flex direction={'column'} justifyContent={'space-between'} gap={'12px'} alignItems={'center'} w={'full'} {...props.attributes} sx={
                                {
                                    color: '#171923',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                }}>
                                <Flex w={'full'} direction={'column'} justifyContent={'center'} alignItems={'center'} gap={'12px'}>
                                    <Text contentEditable={false} color="teal.600" fontSize="14px" fontWeight="600" lineHeight="16px">
                                        {props.element.speaker}
                                    </Text>
                                    <Text color="gray.900" fontSize="16px" fontWeight="400" lineHeight="20px">
                                        {props.children}
                                    </Text>
                                </Flex>
                                <Flex
                                    contentEditable={false}
                                    direction={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    gap={'12px'}
                                >
                                    {isAction && (
                                        <ReviewActionComponent
                                            node={props.element}
                                            onAccept={handleAccept}
                                            onRevert={handleRevert}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                            :
                            null
                        }
                    </>
                )
            default:
                return <DefaultElement {...props} />;

        }
    }, [page, pageSize, filteredValue, value, startIndex, endIndex]);

    const ReviewActionComponent = ({ node, onAccept, onRevert }: { node: any, onAccept: any, onRevert: any }) => {
        const path = ReactEditor.findPath(editor, node)
        let locations: any = {...actionLocations}
        let nodeActions: any[] = locations[path[0]]
        const [filteredActionValue, setFilteredActionValue] = useState<any[]>([]);

        const [actionPage, setActionPage] = useState(1); // initialize the current page to 1
        const [actionPageSize, setActionPageSize] = useState(1);
        const actionStartIndex = (actionPage - 1) * actionPageSize;
        const actionEndIndex = actionStartIndex + actionPageSize;

        useEffect(() => {
            // Filter the value for elements that have 'hasAction' or 'hasComment'
            let newChildren = node.children.map((children: any, index: any) => {
                let newChildren = { ...children, id: index };
                return newChildren;
            })
            const actionOrCommentChildrens = newChildren.filter((children: any) => children.isAction && children.resolved === undefined);

            if (actionOrCommentChildrens.length > 0) {
                setFilteredActionValue(actionOrCommentChildrens)
            } else {
                //onParagraphReviewComplete()
            }
        }, [node]);

        useEffect(() => {
            //set the current action based on the current page, action start index and action end index
            if (filteredActionValue.length > 0) {
                setActionKeyDownData({
                    action: filteredActionValue.slice(actionStartIndex, actionEndIndex)[0],
                    actionId: filteredActionValue.slice(actionStartIndex, actionEndIndex)[0].id,
                    path: path
                })
            }
        }, [filteredActionValue, actionStartIndex, actionEndIndex]);

        return (
            <Flex direction="column" gap={'10px'} align={'center'} justifyContent={'center'} w={'100%'} borderRadius="12px" border="1px" borderColor="gray.300">
                <Flex w={'full'} p="16px" direction={'column'} justifyContent={'flex-start'} alignItems={'center'} gap={'12px'}>
                    <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                        REVIEW
                    </Text>
                </Flex>
                {filteredActionValue.length > 0 &&
                    <Flex w={'full'} direction={'row'} justifyContent={'center'} alignItems={'center'} gap={'12px'}>
                        <CustomPagination
                            totalPages={Math.ceil(filteredActionValue.length / actionPageSize)}
                            currentPage={actionPage}
                            onPageChange={setActionPage}
                            disableKeyboard={true}
                        />
                    </Flex>
                }
                {filteredActionValue.length > 0 && filteredActionValue.slice(actionStartIndex, actionEndIndex).map((action: any, index: any) => {
                    return (
                        <Flex key={action.id} p="16px" direction={'column'} justifyContent={'flex-start'} alignItems={'center'} gap={'12px'}>
                            <Flex direction={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={'12px'}>
                                <Flex direction={'column'}>
                                    <Text fontSize="sm">Original Text:</Text>
                                    <Text fontSize="sm" color={'orange.500'}>{action.original_text}</Text>
                                </Flex>
                                <Flex direction={'column'}>
                                    <Text fontSize="sm">Suggested Text:</Text>
                                    <Text fontSize="sm" color="green.500">{action.proposed_text}</Text>
                                </Flex>
                            </Flex>
                            <ButtonGroup size="sm" spacing="4">
                                <Button variant={'outline'} onClick={() => {onRevert(action, action.id, path)}}>Revert</Button>
                                <Button variant={'solid'} onClick={() => {onAccept(action, action.id, path)}}>Accept</Button>
                            </ButtonGroup>
                        </Flex>
                    )
                })}
                {filteredActionValue.length === 0 &&
                    <Flex p="16px" direction={'column'} justifyContent={'flex-start'} alignItems={'center'} gap={'12px'}>
                        <CheckIcon color={'teal'} boxSize={'48px'}/>
                        <Text fontSize="sm" color={'gray.500'}>No more actions to review for this paragraph</Text>
                    </Flex>
                }
            </Flex>
        );
    };
    const handleAccept = (action: any, index: any, path: any) => {
        let end = index
        let newAction = {...action}
        newAction.currently_displayed_text = 'proposal'
        newAction.text = action.proposed_text
        newAction = {
            ...newAction,
            resolved: true,
            resolved_by: user.user.id,
            resolved_at: new Date()
        }

            Transforms.setNodes(
                editor,
                {
                    ...newAction,
                },
                { at: [path[0], end] }
            )

        handleSaveClick()
    };

    const handleAcceptAll = async () => {
        toast({
            title: "Processing all actions",
            description: "Starting bulk accept process...",
            status: "info",
            duration: 2000,
            isClosable: true,
        });

        let changesCount: number = 0;

        try {
            // Create a snapshot of the current editor state
            const currentValue = [...editor.children];

            // Process each paragraph
            for (let paragraphIndex = 0; paragraphIndex < currentValue.length; paragraphIndex++) {
                const paragraph: any = currentValue[paragraphIndex];

                if (!paragraph?.children || !Array.isArray(paragraph.children)) continue;

                // Create a queue of changes to apply
                const changes = [];

                // First, collect all the changes we need to make
                for (let childIndex = 0; childIndex < paragraph.children.length; childIndex++) {
                    const child = paragraph.children[childIndex];

                    if (!child?.isAction || child.resolved === true) {
                        continue;
                    }

                    changes.push({
                        path: [paragraphIndex, childIndex],
                        properties: {
                            currently_displayed_text: 'proposal',
                            text: child.proposed_text,
                            resolved: true,
                            resolved_by: user.user.id,
                            resolved_at: new Date()
                        }
                    });
                }

                // Then apply the changes in reverse order to maintain path validity
                for (const change of changes.reverse()) {
                    try {
                        // Verify the node still exists at this path
                        const [node] = Editor.node(editor, change.path);
                        if (!node) continue;

                        Transforms.setNodes(
                            editor,
                            change.properties,
                            { at: change.path }
                        );

                        changesCount++;

                        // Small delay to prevent overwhelming the editor
                        await new Promise(resolve => setTimeout(resolve, 10));
                    } catch (error) {
                        console.error('Transform error at path:', change.path, error);
                        continue;
                    }
                }
            }

            if (changesCount === 0) {
                toast({
                    title: "No changes needed",
                    description: "All actions have already been resolved",
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }

            // Update UI state
            calculateTotalResolvedActions(editor.children);
            setPage(1);

            // Update proposals based on editor's current state
            const newActions = filterActionItems(editor.children);
            setProposal(newActions);

            // Trigger a save after bulk accept
            handleSaveClick();

            toast({
                title: "Success",
                description: `Successfully processed ${changesCount} changes`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Bulk accept error:', error);
            toast({
                title: "Error",
                description: "There was an error processing changes. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRevert = (action: any, index: any, path: any) => {
        let end = index

        let newAction = {...action}
        newAction.currently_displayed_text = 'original'
        if (newAction.proposed_text.endsWith(' ') && !newAction.original_text.endsWith(' ')) {
            newAction.original_text = newAction.original_text + ' '
        }
        newAction.text = action.original_text
        newAction = {
            ...newAction,
            resolved: true,
            resolved_by: user.user.id,
            resolved_at: new Date()
        }

        Transforms.insertText(editor, newAction.original_text, { at: [path[0], end] })

        Transforms.setNodes(
            editor,
            {
                ...newAction,
            },
            { at: [path[0], end] }
        )

        handleSaveClick()
    };
    const handleOnKeyDown = (event: any) => {
        //handle ctrl + z
        if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
            event.preventDefault()
            editor.undo()
        }

        if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
            event.preventDefault()
            handleAccept(actionKeyDownData.action, actionKeyDownData.actionId, actionKeyDownData.path)
        }

        if ((event.ctrlKey || event.metaKey) && event.key === 'r') {
            event.preventDefault()
            handleRevert(actionKeyDownData.action, actionKeyDownData.actionId, actionKeyDownData.path)
        }

        //handle arrow right
        if (event.key === 'ArrowRight') {
            event.preventDefault()
            if (page < Math.ceil(filteredValue.length / pageSize)) {
                setPage(page + 1)
            }
        }

        //handle arrow left
        if (event.key === 'ArrowLeft') {
            event.preventDefault()
            if (page > 1) {
                setPage(page - 1)
            }
        }
    }

    const renderLeaf = useCallback(({ attributes, children, leaf }: { attributes: any, children: any, leaf: any }) => {
        const extendedAttributes = {
            ...attributes,
            'data-start': leaf.start,
            'data-end': leaf.end
        };

        let parentNode = leaf.parent

        if (leaf.isAdvertisement) {
            if (leaf.isAction) {
                children = (
                    <span style={{
                        backgroundColor: leaf.resolved !== undefined && leaf.resolved === true ? 'transparent': '#E6FFFA',
                        color: '#2B6CB0'
                    }}>
                {children}
            </span>
                );
            } else {
                children = <span style={{ backgroundColor: '#FFFFF0', color: '#B7791F' }}>{children}</span>;
            }
        } else if (leaf.isInaudibleContent) {
            if (leaf.isAction) {
                children = (
                    <span style={{
                        backgroundColor: leaf.resolved !== undefined && leaf.resolved === true ? 'transparent': '#EBF8FF',
                        color: '#ffa500'
                    }}>
                {children}
            </span>
                );
            } else {
                children = <span style={{ backgroundColor: '#FFF5F5', color: '#C53030'}}>{children}</span>;
            }
        } else {
            children = <span style={{ backgroundColor: 'transparent' }}>{children}</span>;
        }

        if (leaf.isComment) {
            if (leaf.isAction) {
                children = (
                    <span style={{
                        backgroundColor: leaf.resolved !== undefined && leaf.resolved === true ? 'transparent': '#E6FFFA',
                        color: '#319795'
                    }}>
                {children}
            </span>
                );
            } else {
                children = <span style={{ backgroundColor: '#EBF8FF', color: '#2B6CB0' }}>{children}</span>;
            }
        } else if (leaf.isAction) {
            children = (
                <span
                    style={{
                        backgroundColor: leaf.resolved !== undefined && leaf.resolved === true ? 'transparent': '#E6FFFA',
                        color: '#ffa500'
                    }}
                >
            {children}
        </span>
            );
        }


        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underlined) {
            children = <u>{children}</u>
        }

        return <span {...extendedAttributes}>{children}</span>;
    }, [])
    const removeWordsParamForChildren = (transcript: any) => {
        let newTranscript = transcript.map((paragraph: any) => {
            let newParagraph = { ...paragraph };
            newParagraph.children = paragraph.children.map((children: any) => {
                let newChildren = { ...children };
                delete newChildren.words;
                return newChildren;
            });
            return newParagraph;
        });
        return newTranscript;
    }

    const handleSaveClick = () => {
        if (!transcriptSaving) {
            setTranscriptSaving(true)
            // Create a new complete transcript by merging filtered changes back into the main value
            const updatedTranscript = value.map((paragraph: any) => {
                const filteredParagraph = filteredValue.find((fp: any) => fp.id === paragraph.id);
                if (filteredParagraph) {
                    return filteredParagraph;  // Use the updated version from filteredValue
                }
                return paragraph;  // Keep unchanged paragraphs as is
            });

            const data = removeWordsParamForChildren(updatedTranscript)
            transcriptSaveReq.setPayloadData({transcript: data})
        } else {
            toast({
                title: "Transcript is already saving",
                description: "Please wait until the transcript is saved",
                status: "warning",
                duration: 3000,
                isClosable: true,
            })
        }
    };

    // Update the submit handler
    const handleSubmit = () => {
        setSubmitting(true)
        // Create a new complete transcript by merging filtered changes back into the main value
        const updatedTranscript = value.map((paragraph: any) => {
            const filteredParagraph = filteredValue.find((fp: any) => fp.id === paragraph.id);
            if (filteredParagraph) {
                return filteredParagraph;  // Use the updated version from filteredValue
            }
            return paragraph;  // Keep unchanged paragraphs as is
        });

        const data = removeWordsParamForChildren(updatedTranscript)
        transcriptSubmitReq.setPayloadData({transcript: data})
    };

    return (
        <Flex w={'full'} h={'100vh'} justifyContent={'space-between'} direction="column" p={'0px'} gap={'32px'}>
            <Flex p={'16px'}  w={'full'} bgColor={'white'} alignItems={'flex-start'} direction={'row'} gap={'16px'}>
                <Flex direction={'row'} alignItems={'center'} p={'20px'} justifyContent={'space-between'} ref={transcriptRef} w={'full'} h={'full'}>
                    {transcriptLoading ? (
                        <SkeletonText w={'full'} h={'full'} mt='4' noOfLines={20} spacing='4' skeletonHeight='2' />
                    ) : (
                        <Flex direction={'column'} gap={'10px'} alignItems={'center'} justifyContent={'center'} w={'100%'} h={'100%'}>
                            <Slate editor={editor} onChange={handleChange} value={value}>
                                <Editable
                                    renderElement={renderElement}
                                    readOnly={false}
                                    renderLeaf={renderLeaf}
                                    onKeyDown={handleOnKeyDown}
                                    autoFocus
                                    style={{ height: '100%', overflowY: 'scroll' }}
                                />
                            </Slate>
                            <Flex w={'full'} direction={'column'} justifyContent={'center'} alignItems={'center'} gap={'12px'}>
                                <CustomParagraphPagination
                                    totalPages={Math.ceil(filteredValue.length / pageSize)}
                                    currentPage={page}
                                    onPageChange={setPage}
                                />
                                <Tag colorScheme={totalResolvedActions === totalReviewActions ? 'teal' : 'red'} size={'lg'} borderRadius={'full'}>
                                    {totalResolvedActions}/{totalReviewActions} Actions resolved
                                </Tag>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </Flex>

            <Flex
                position="sticky"
                bottom={0}
                w="100%"
                h={20}
                bg="white"
                boxShadow={'lg'}
                justifyContent="center"
                alignItems="center"
                zIndex={2}>
                <Center justifyContent={'center'} alignItems={'center'} w={'full'} gap={'36px'}>
                    <Button onClick={handleAcceptAll} isDisabled={totalResolvedActions === totalReviewActions} w={'30%'}>
                        Accept All
                    </Button>
                    <Button
                        isDisabled={task?.state === TranscriptionState.COMPLETED}
                        w={'30%'}
                        onClick={() => {
                            if (!transcriptSaving) {
                                submitOnOpen()
                            } else {
                                toast({
                                    title: "Transcript is currently saving",
                                    description: "Please wait until the transcript is saved",
                                    status: "warning",
                                    duration: 3000,
                                    isClosable: true,
                                })
                            }
                        }}
                        variant={'solid'}
                        size="md"
                        colorScheme="teal"
                    >
                        Complete Review
                    </Button>
                    <Button
                        isDisabled={transcriptSaving || submitting || transcriptLoading}
                        w={'30%'}
                        onClick={() => {
                            if (!transcriptSaving) {
                                handleSaveClick()
                            } else {
                                toast({
                                    title: "Transcript is already saving",
                                    description: "Please wait until the transcript is saved",
                                    status: "warning",
                                    duration: 3000,
                                    isClosable: true,
                                })
                            }
                        }}
                        variant={'outline'}
                        size="md"
                        colorScheme="teal"
                    >
                        Save
                    </Button>
                </Center>
            </Flex>

            <PopoverMenu user={user.user} editor={editor} isOpen={customIsOpen} onOpen={customOnOpen} onClose={customOnClose} />
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={submitOnClose}
                isOpen={submitIsOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>Submit task?</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody w={'full'} justifyContent={'center'} alignItems={'center'}>
                        {submitting ? (
                            <Flex w={'full'} justifyContent={'center'} alignItems={'center'}>
                                <Spinner alignSelf={'center'} color={'teal'}/>
                            </Flex>
                        ) : (
                            <Text color="gray.900" fontSize="16px" fontWeight="400" lineHeight="20px">
                                Are you sure you're done with review? This episode will now proceed to word-timestamps alignment.
                            </Text>
                        )}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={submitOnClose}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => {
                            handleSubmit()
                        }}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Flex>
    );
}
