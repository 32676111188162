import {Packer, Paragraph, TextRun} from "docx";
import { saveAs } from "file-saver";

export const removePTags = (input: string): string => {
    return input.replace(/<\/?p>/g, '');
}

export const secondsToHHMMSS = (seconds: number): string => {
    // Calculate the number of hours, minutes, and seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Format the values as two-digit strings with leading zeros, if needed
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secsStr = secs.toString().padStart(2, '0');

    // Return the formatted time string
    return `${hoursStr}:${minutesStr}:${secsStr}`;
}

export const secondsToMinutes = (seconds: number): string => {
    // Calculate the number of hours, minutes, and seconds
    const minutes = Math.floor(seconds / 60);

    return `${minutes.toString()}`;

}

const generate = () => {
    // @ts-ignore
    const doc: any = new Document({
        sections: [
            {
                properties: {},
                children: [
                    new Paragraph({
                        children: [
                            new TextRun("Hello World"),
                            new TextRun({
                                text: "This is bold",
                                bold: true,
                            }),
                            new TextRun({
                                text: "\tThis is a tab",
                                bold: true,
                            }),
                        ],
                    }),
                ],
            },
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "example.docx");
    });
};
